
import { defineComponent, ref } from "vue";
import * as QuestionnaireServices from "../api/helpers/Questionnaire";
import * as LeadServices from "../api/helpers/Lead";
import * as ApplicationServices from "../api/helpers/Application";
import TypeText from "../components/TypeText.vue";
import TypePhone from "../components/TypePhone.vue";
import TypeSwitch from "../components/TypeSwicth.vue";
import TypeEmail from "../components/TypeEmail.vue";
import TypeRadioButton from "../components/TypeRadioButton.vue";
import TypeYesNoButton from "../components/TypeYesNoButton.vue";
import TypeDropDown from "../components/TypeDropDown.vue";
import TypeDate from "../components/TypeDate.vue";
import TypeCheck from "../components/TypeCheck.vue";
import Spinner from "../components/Spinner.vue";
import Questionnaire from "../models/Questionnaire";
import * as FacebookServices from "../api/helpers/Facebook";
import _ from "underscore";
import { getCurrentInstance } from "vue";
import "particles.js";
window.particlesJS = window.particlesJS || {};
export default defineComponent({
  name: "Register",
  components: {
    TypeText,
    TypePhone,
    TypeEmail,
    TypeSwitch,
    TypeRadioButton,
    TypeYesNoButton,
    TypeDropDown,
    TypeDate,
    TypeCheck,
    Spinner,
  },
  data() {
    return {
      currentStep: 0,
      submitted: false,
      isvalid: true,
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      phoneLength: process.env.VUE_APP_PHONE_LENGTH,
      birthdate: new Date(),
      maxSteps: 0,
      minStep: 0,
      answers: {},
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
    };
  },
  setup() {
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;
    var loading = ref(true);
    var questionnaire = ref({});
    const questionnaires = ref({ Questionnaire });
    const wrongEmail = ref(false);
    const getQuestionnaire = async () => {
      questionnaire.value = await QuestionnaireServices.find_Register().data;
    };

    return {
      questionnaire,
      questionnaires,
      getQuestionnaire,
      loading,
      toastTime,
      wrongEmail
    };
  },
  mounted() {
    FacebookServices.pageView();
    this.initParticles();
    document.title = process.env.VUE_APP_TITLE + " | Registro";
    window.scrollTo(0, 0);
    let { min, max } = this.getSteps();
    this.minStep = min;
    this.maxSteps = max;
    this.currentStep = Number(sessionStorage.getItem("step")) || this.minStep;
    if (
      this.currentStep > this.minStep &&
      (!this.leadId || !this.applicationId)
    )
      this.goFirst();
    this.to(this.currentStep);
    this.loading = false;
    const to = this.to;
    const router = this.$router;
    window.onpopstate = function() {
      const urlParams = new URLSearchParams(window.location.search);
      const step = urlParams.get("step");
      if (!step && Number(sessionStorage.getItem("step")) > max){
        router.push({
          path: "resultados",
          query: { SID: sessionStorage.getItem("SID") },
        });
      }
      else {
        const next = parseInt(step || "0") || undefined;
        to(next);
      }
    };
  },
  methods: {
    getSteps() {
      this.getQuestionnaire();
      this.questionnaires.Questionnaire.prototype = QuestionnaireServices.find_Register().data;
      const max = Math.max(
        ..._.pluck(this.questionnaires.Questionnaire.prototype.steps, "step")
      );
      const min = Math.min(
        ..._.pluck(this.questionnaires.Questionnaire.prototype.steps, "step")
      );
      this.currentStep = Number(sessionStorage.getItem("step")) || min;

      return { min, max };
    },
    register() {
      this.submitted = true;
      this.isvalid = true;
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        if (this.currentStep == x.step) {
          this.isvalid =
            !_.some(x.answers, (y) => y.valid == false) && this.isvalid;
          for (var y of x.answers) {
            if (!y.valid) {
              this.isvalid = false;
              break;
            }
            Object.assign(this.answers, { [y.key]: y.value });
          }
        }
      }
      if (this.isvalid) {
        this.loading = true;
        this.submitted = false;
        if (this.currentStep == this.minStep) {
          if (this.leadId) this.updateLead();
          else this.createLead();
        } else {
          this.updateApplication();
        }
      }
    },
    setValue(obj: any) {
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        if (this.currentStep == x.step) {
          for (var y of x.answers) {
            if (y.key == obj.answerKey) {
              Object.assign(y, { value: obj.value });
              Object.assign(y, { valid: obj.valid });
              break;
            }
          }
        }
      }
    },
    createLead() {
      const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
      sessionStorage.setItem(
        "steps",
        JSON.stringify(Object.assign(steps, this.answers))
      );
      LeadServices.create(this.answers).then(
        (res) => {
          const {
            data: { lead, application, newEmail },
          } = res.data;
          this.wrongEmail = false;
          sessionStorage.setItem("newEmail", newEmail ? "1" : "0");
          sessionStorage.setItem("leadId", lead.id);
          sessionStorage.setItem("applicationId", application.id);
          this.leadId = lead.id;
          this.applicationId = application.id;
          this.to(this.currentStep + 1);
        },
        (err) => {
          if(err.response?.data.message == 'Correo electrónico inválido') { 
            this.wrongEmail = true;
          }
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: err,
            detail: err.response?.data.message,
            life: this.toastTime,
          });
        }
      );
    },
    updateLead() {
      if (this.leadId) {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        LeadServices.update(this.answers, this.leadId).then(
          (res) => {
            const newEmail = res.data.data.newEmail;
            if (_.has(this.answers, "email"))
              sessionStorage.setItem("newEmail", newEmail ? "1" : "0");

            sessionStorage.setItem(
              "steps",
              JSON.stringify(Object.assign(steps, this.answers))
            );
            this.wrongEmail = false;
            this.to(this.currentStep + 1);
          },
          (err) => {
            if(err.response?.data.message == 'Correo electrónico inválido') {
              this.wrongEmail = true;
            }
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              detail: err.response?.data.message,
              life: this.toastTime,
            });
          }
        );
      }
    },
    updateApplication() {
      if (this.applicationId) {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        ApplicationServices.update(this.answers, this.applicationId).then(
          () => {
            sessionStorage.setItem(
              "steps",
              JSON.stringify(Object.assign(steps, this.answers))
            );
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              detail: err.response?.data.message,
              life: this.toastTime,
            });
          }
        );
      }
    },
    to(step?: number) {
      if (!step || step < this.minStep) {
        this.$router.push("../");
      } else if (step - 1 > this.maxSteps) {
        return;
      } else {
        this.currentStep = step;
        sessionStorage.setItem("step", this.currentStep.toString());
        if (this.currentStep > this.maxSteps) {
          sessionStorage.setItem("capiLeadId", "true");
          sessionStorage.setItem(
            "questionnaire",
            JSON.stringify(this.questionnaires.Questionnaire.prototype.steps)
          );
          this.$router.push({
            path: "resultados",
            query: { SID: sessionStorage.getItem("SID") },
          });
        } else {
          this.loading = this.submitted = false;
          window.scrollTo(0, 0);
          this.$router.push({
            path: "registro",
            query: { step: this.currentStep },
          });
        }
      }
    },
    goFirst() {
      sessionStorage.removeItem("leadId");
      sessionStorage.removeItem("applicationId");
      this.leadId = null;
      this.applicationId = null;
      this.to(this.minStep);
    },
    resetWrongEmail() {
      this.wrongEmail = false;
    },
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 250,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 10,
            },
          },
          opacity: {
            value: 0.75,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              opacity_min: 0.2,
              sync: true,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 0,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
});
